import React from 'react';
import PropTypes from 'prop-types';
import {
  IconLeetcode,
  IconGitHub,
  IconLinkedin,
  IconLoader,
  IconLogo,
  IconTelegram,
} from '@components/icons';

const Icon = ({ name }) => {
  switch (name) {
    case 'Leetcode':
      return <IconLeetcode />;
    case 'GitHub':
      return <IconGitHub />;
    case 'Linkedin':
      return <IconLinkedin />;
    case 'Loader':
      return <IconLoader />;
    case 'Logo':
      return <IconLogo />;
    case 'Telegram':
      return <IconTelegram />;
    default:
      return null;
  }
};

Icon.propTypes = {
  name: PropTypes.string.isRequired,
};

export default Icon;
