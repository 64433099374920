import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import styled, { css } from 'styled-components';
import { navLinks } from '@config';
import { loaderDelay } from '@utils';
import { useScrollDirection, usePrefersReducedMotion } from '@hooks';
import { Menu } from '@components';
import { IconLogo } from '@components/icons';
import { scroller } from 'react-scroll';

const StyledHeader = styled.header`
  ${({ theme }) => theme.mixins.flexBetween};
  position: fixed;
  top: 0;
  z-index: 11;
  padding: 0px 50px;
  width: 100%;
  height: var(--nav-scroll-height);
  background-color: var(--dark-blue);
  filter: none !important;
  pointer-events: auto !important;
  user-select: auto !important;
  transition: var(--transition);
  border-bottom: 1px solid rgba(87, 76, 242, 0.6);

  @media (max-width: 1080px) {
    padding: 0 40px;
  }

  @media (max-width: 768px) {
    padding: 0 25px;
  }

  @media (prefers-reduced-motion: no-preference) {
    ${props =>
    props.scrollDirection === 'up' &&
      css`
        height: var(--nav-scroll-height);
        transform: translateY(0px);
        background-color: var(--dark-blue);
      `};

    ${props =>
    props.scrollDirection === 'down' &&
      css`
        height: var(--nav-scroll-height);
        transform: translateY(calc(var(--nav-scroll-height) * -1));
      `};
  }
`;

const StyledNav = styled.nav`
  ${({ theme }) => theme.mixins.flexBetween};
  position: relative;
  width: 100%;
  color: var(--slate);
  counter-reset: item 0;
  z-index: 12;

  .logo {
    ${({ theme }) => theme.mixins.flexCenter};

    a {
      width: 42px;
      height: 42px;

      svg {
        fill: var(--slate);
        transition: var(--transition);
        user-select: none;
      }
    }
  }
`;

const StyledLinks = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    display: none;
  }

  ol {
    ${({ theme }) => theme.mixins.flexBetween};
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      margin: 0 5px;
      position: relative;
      counter-increment: item 1;
      font-size: var(--fz-lgd);
      font-weight: 300;

      a {
        padding: 10px;
        cursor: pointer;

        &:before {
          content: '0' counter(item) '.';
          margin-right: 5px;
          color: var(--pink-light);
          font-size: var(--fz-xs);
          text-align: right;
        }
      }
    }
  }

  .resume-button {
    ${({ theme }) => theme.mixins.smallButton};
    margin-left: 15px;
  }
`;

const Nav = ({ isHome }) => {
  const [isMounted, setIsMounted] = useState(!isHome);
  const scrollDirection = useScrollDirection('down');
  const [scrolledToTop, setScrolledToTop] = useState(true);
  const prefersReducedMotion = usePrefersReducedMotion();

  const handleScroll = () => {
    setScrolledToTop(window.pageYOffset < 50);
  };

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    const timeout = setTimeout(() => {
      setIsMounted(true);
    }, 100);

    window.addEventListener('scroll', handleScroll);

    return () => {
      clearTimeout(timeout);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollTo = (e, container) => {
    if (isHome) {
      e.preventDefault();
      scroller.scrollTo(container, {
        smooth: true,
        offset: -30,
      });
    }
  };

  const timeout = isHome ? loaderDelay : 0;
  const fadeClass = isHome ? 'fade' : '';
  const fadeDownClass = isHome ? 'fadedown' : '';

  const Logo = (
    <div className="logo" tabIndex="-1">
      {isHome ? (
        <a href="/" aria-label="home">
          <IconLogo />
        </a>
      ) : (
        <Link to="/" aria-label="home">
          <IconLogo />
        </Link>
      )}
    </div>
  );

  const ResumeLink = (
    <a className="resume-button" href="/resume.pdf" target="_blank" rel="noopener noreferrer">
      <span className="button-text">Resume</span>
      <div className="button-mask"></div>
    </a>
  );

  return (
    <>
      {isHome && (
        <StyledHeader scrollDirection={scrollDirection} scrolledToTop={scrolledToTop}>
          <StyledNav>
            {prefersReducedMotion ? (
              <>
                {Logo}
                <>
                  <StyledLinks>
                    <ol>
                      {navLinks &&
                        navLinks.map(({ url, name }, i) => (
                          <li key={i}>
                            <Link to={isHome ? '#' : '/'} onClick={e => scrollTo(e, url)}>
                              {name}
                            </Link>
                          </li>
                        ))}
                    </ol>
                    <div>{ResumeLink}</div>
                  </StyledLinks>
                  <Menu isHome={isHome} />
                </>
              </>
            ) : (
              <>
                <TransitionGroup component={null}>
                  {isMounted && (
                    <CSSTransition classNames={fadeClass} timeout={timeout}>
                      <>{Logo}</>
                    </CSSTransition>
                  )}
                </TransitionGroup>
                <>
                  <StyledLinks>
                    <ol>
                      <TransitionGroup component={null}>
                        {isMounted &&
                          navLinks &&
                          navLinks.map(({ url, name }, i) => (
                            <CSSTransition key={i} classNames={fadeDownClass} timeout={timeout}>
                              <li key={i} style={{ transitionDelay: `${isHome ? i * 100 : 0}ms` }}>
                                <Link to={isHome ? '#' : '/'} onClick={e => scrollTo(e, url)}>
                                  {name}
                                </Link>
                              </li>
                            </CSSTransition>
                          ))}
                      </TransitionGroup>
                    </ol>
                    <TransitionGroup component={null}>
                      {isMounted && (
                        <CSSTransition classNames={fadeDownClass} timeout={timeout}>
                          <div
                            style={{ transitionDelay: `${isHome ? navLinks.length * 100 : 0}ms` }}>
                            {ResumeLink}
                          </div>
                        </CSSTransition>
                      )}
                    </TransitionGroup>
                  </StyledLinks>

                  <TransitionGroup component={null}>
                    {isMounted && (
                      <CSSTransition classNames={fadeClass} timeout={timeout}>
                        <Menu isHome={isHome} />
                      </CSSTransition>
                    )}
                  </TransitionGroup>
                </>
              </>
            )}
          </StyledNav>
        </StyledHeader>
      )}
    </>
  );
};

Nav.propTypes = {
  isHome: PropTypes.bool,
};

export default Nav;
