import { css } from 'styled-components';

const button = css`
  cursor: pointer;
  text-decoration: none;
  background: transparent;
  padding: 0;
  border: 0;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  white-space: nowrap;
  display: inline-block;
  line-height: 0.9;
  text-align: center;
  vertical-align: middle;
  font-size: 0.875em;
  letter-spacing: 1px;
  padding: 12px 20px;
  border: 1px solid var(--pink-light);
  color: var(--slate);
  position: relative;
  transition: all 0.3s;

  .button-text {
    position: relative;
    z-index: 10;
  }

  .button-mask {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    &:after {
      will-change: transform;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      transform: translateX(-110%) skewX(-10deg);
      width: 120%;
      background-color: var(--pink-light);
      transition: transform 0.4s cubic-bezier(0.694, 0.048, 0.335, 1);
      z-index: 2;
    }
  }

  &:hover {
    .button-mask {
      &:after {
        transform: translateX(-10%) skewX(-10deg);
      }
    }
  }
`;

const mixins = {
  flexCenter: css`
    display: flex;
    justify-content: center;
    align-items: center;
  `,

  flexBetween: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,

  link: css`
    display: inline-block;
    text-decoration: none;
    text-decoration-skip-ink: auto;
    color: inherit;
    position: relative;
    transition: var(--transition);

    &:hover,
    &:active,
    &:focus {
      color: var(--pink-light);
      outline: 0;
    }
  `,

  inlineLink: css`
    display: inline-block;
    text-decoration: none;
    text-decoration-skip-ink: auto;
    position: relative;
    transition: var(--transition);
    color: var(--pink-light);
    font-weight: 600;

    &:hover,
    &:focus,
    &:active {
      color: var(--pink-light);
      outline: 0;
      &:after {
        width: 100%;
      }
      & > * {
        color: var(--pink-light) !important;
        transition: var(--transition);
      }
    }

    &:after {
      content: '';
      display: block;
      width: 0;
      height: 1px;
      position: relative;
      bottom: 0.37em;
      background-color: var(--pink-light);
      transition: var(--transition);
      opacity: 0.5;
    }
  `,

  smallButton: css`
    ${button}
    padding: 0.75rem 1rem;
  `,

  bigButton: css`
    ${button}
    padding: 1.25rem 1.75rem;
  `,

  boxShadow: css`
    box-shadow: 0 10px 30px -15px var(--navy-shadow);
    transition: var(--transition);

    &:hover,
    &:focus {
      box-shadow: 0 20px 30px -15px var(--navy-shadow);
    }
  `,

  resetList: css`
    list-style: none;
    padding: 0;
    margin: 0;
  `,
};

export default mixins;
