import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import anime from 'animejs';
import styled from 'styled-components';
import { IconLogo } from '@components/icons';

const StyledLoader = styled.div`
  .loading__wrapper {
    ${({ theme }) => theme.mixins.flexCenter};
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0
    width: 100%;
    height: 100%;
    z-index: 1000;
    user-select: none;

    .bg__transition--slide {
      background: var(--light-navy);
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
    }

    svg {
      height: 5vh;
      width: auto;
      margin: 0 3px;
      opacity: 0;
      fill: var(--slate);
    }

    .letter {
      transform: translateX(-110vw);
    }

    .letter,
    .dot {
      font-size: 5vh;
      color: var(--slate);
      font-family: var(--font-sans);
      padding-top: 10px;
      font-weight: bold;
      text-transform: uppercase;
    }

    .dot {
      opacity: 0;
      color: var(--pink-light);
    }
  }
`;

const Loader = ({ finishLoading }) => {
  const [isMounted, setIsMounted] = useState(false);

  const animate = () => {
    const loader = anime.timeline({
      complete: () => finishLoading(),
    });

    loader
      .add({
        targets: 'svg',
        translateX: [
          {
            value: '200%',
            duration: 0,
            delay: 0,
            easing: 'linear',
          },
          {
            value: 0,
            duration: 500,
            delay: 1000,
          },
        ],
        rotate: {
          value: 360,
          duration: 500,
          delay: 500,
          easing: 'easeInOutSine',
        },
        opacity: {
          value: 1,
          duration: 500,
          easing: 'easeInOutSine',
        },
      })
      .add({
        targets: '.letter',
        translateX: ['-110vw', 0],
        direction: 'alternate',
        loop: false,
        delay: 200,
        duration: 1000,
      })
      .add({
        targets: '.dot',
        duration: 1000,
        easing: 'easeInOutQuart',
        opacity: 1,
      })
      .add({
        targets: '.letter, svg, .dot',
        duration: 700,
        easing: 'easeInOutQuart',
        opacity: 0,
      })
      .add({
        targets: '.bg__transition--slide',
        duration: 500,
        translateY: [0, '-150%'],
        transformOrigin: '50% 0 0',
        easing: 'easeOutExpo',
        delay: 500,
      });
  };

  useEffect(() => {
    const timeout = setTimeout(() => setIsMounted(true), 10);
    animate();
    return () => clearTimeout(timeout);
  }, []);

  return (
    <StyledLoader classNameName="loader" isMounted={isMounted}>
      <Helmet bodyAttributes={{ className: `hidden` }} />
      <div className="loading__wrapper">
        <span className="letter">y</span>
        <span className="letter">u</span>
        <span className="letter">d</span>
        <span className="letter">i</span>
        <span className="letter">n</span>
        <span className="letter">k</span>
        <IconLogo className="logo" />
        <span className="letter">v</span>
        <span className="dot">.dev</span>
        <div className="bg__transition--slide"></div>
      </div>
    </StyledLoader>
  );
};

Loader.propTypes = {
  finishLoading: PropTypes.func.isRequired,
};

export default Loader;
