import * as THREE from 'three';
import React, { useRef } from 'react';
import { useFrame } from '@react-three/fiber';
import PropTypes from 'prop-types';

import { useGLTF, MeshDistortMaterial, Shadow } from '@react-three/drei';

import model from '@models/geo.min.glb';

function Model(props) {
  const group = useRef();
  const shadow = useRef();
  const { nodes } = useGLTF(model, true);
  const positions = props?.mouse || [];
  useFrame(({ clock }) => {
    const t = (1 + Math.sin(clock.getElapsedTime() * 1.5)) / 2;
    group.current.position.y = t / 3;
    shadow.current.scale.y = shadow.current.scale.z = 1 + t;
    shadow.current.scale.x = (1 + t) * 1.25;
    group.current.rotation.x = group.current.rotation.z += 0.005;
    group.current.position.x = THREE.MathUtils.lerp(
      group.current.position.x,
      positions[0] / 5 || 0,
      0.05,
    );
    group.current.position.z = THREE.MathUtils.lerp(
      group.current.position.z,
      0,
      positions[1] / 10 || 0,
    );
  });
  return (
    <group {...props} dispose={null}>
      <group ref={group}>
        <mesh geometry={nodes.geo.geometry} castShadow receiveShadow>
          <MeshDistortMaterial
            color="#c8638a"
            flatShading
            roughness={1}
            metalness={0.5}
            factor={15}
            speed={5}
          />
        </mesh>
        <mesh geometry={nodes.geo.geometry}>
          <meshBasicMaterial wireframe />
        </mesh>
      </group>
      <Shadow ref={shadow} opacity={0.3} rotation-x={-Math.PI / 2} position={[0, -1.51, 0]} />
    </group>
  );
}

Model.propTypes = {
  mouse: PropTypes.array,
};

export default Model;
