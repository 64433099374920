import React, { useEffect, useRef } from 'react';
import { extend, useThree, useFrame } from '@react-three/fiber';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass';
import { GammaCorrectionShader } from 'three/examples/jsm/shaders/GammaCorrectionShader';

extend({ EffectComposer, ShaderPass, RenderPass });

export default function Effects() {
  const composer = useRef();
  const { gl, size, camera, scene } = useThree();
  useEffect(() => void composer.current.setSize(size.width, size.height), [size]);
  let last = 0;
  let index = 0;
  const values = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  useFrame(() => {
    const top = 0;
    values[index] = Math.abs(top - last);
    last = top;
    index = (index + 1) % 10;
    gl.autoClear = true;
    composer.current.render();
  }, 1);
  return (
    <effectComposer ref={composer} args={[gl]}>
      <renderPass attachArray="passes" scene={scene} camera={camera} />
      <shaderPass attachArray="passes" args={[GammaCorrectionShader]} />
    </effectComposer>
  );
}
