module.exports = {
  email: 'eduard.iudinkov@gmail.com',

  socialMedia: [
    {
      name: 'Linkedin',
      url: 'https://www.linkedin.com/in/eduard-iudinkov-53315a175/',
    },
    {
      name: 'GitHub',
      url: 'https://github.com/eyudinkov/',
    },
    {
      name: 'Leetcode',
      url: 'https://leetcode.com/u/eyudinkov/',
    },
  ],

  navLinks: [
    {
      name: 'Home',
      url: 'home',
    },
    {
      name: 'About me',
      url: 'about',
    },
    {
      name: 'My projects',
      url: 'projects',
    },
    {
      name: 'Contact',
      url: 'contact',
    },
  ],

  colors: {
    pink: '#eea1be',
    navy: '#18033c',
    blue: '#420fe7',
  },

  srConfig: (delay = 200, viewFactor = 0.25) => ({
    origin: 'bottom',
    distance: '20px',
    duration: 500,
    delay,
    rotate: {
      x: 0,
      y: 0,
      z: 0,
    },
    opacity: 0,
    scale: 1,
    easing: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
    mobile: true,
    reset: false,
    useDelay: 'always',
    viewFactor,
    viewOffset: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
  }),
};
